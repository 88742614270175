import React, { useState, useContext } from "react";
import MUIDataTable from "mui-datatables";
import { useQuery } from "react-apollo-hooks";
import { observer } from "mobx-react-lite";
import { textLabels } from "../dataTablesLocalization";
import { StoreContext } from "../../store";
import {
  Grid,
  NativeSelect,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import gql from "graphql-tag";
import Application from "./Application";

const List = observer((props) => {
  const store = useContext(StoreContext);
  const [state, setState] = useState({
    semestre: "2025-1",
    course: store.ui.turma.course,
  });
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  const handleChangeCourse = (event) => {
    setState({
      ...state,
      course: event.target.value,
    });
  };
  //if(store.ui.turma.course!==state.course) setState({...state, course:store.ui.turma.course})
  var { data, error, loading, refetch } = useQuery(query, { variables: state });
  var courses = useQuery(selectCourses);
  if (state.application)
    return <Application id={state.application.id} refetch={refetch} />;
  if (loading) return <p>Carregando... </p>;
  if (error) return <p>ERROR (ApplicationList.jsx): {error.message}</p>;
  data = JSON.parse(JSON.stringify(data.application));
  var dataView = JSON.parse(JSON.stringify(data)); //clone data to preserve original object
  dataView = dataView.map((d) => {
    d = { status: d.status, ...d.personByPerson, ...d };
    delete d.personByPerson;
    d.consultor = d.consultant ? d.consultant.personByPerson.first_name : null;
    delete d.consultant;
    delete d.paymentPlanByPaymentPlan;
    delete d["__typename"];
    return d;
  });
  var columns = dataView[0] ? Object.keys(dataView[0]) : [];
  columns = columns.map((col) => {
    if (hidden_columns.includes(col))
      col = {
        name: col,
        options: { display: false },
      };
    return col;
  });
  return (
    <>
      <div>
        <FormControl className="formControl" style={{ marginRight: 15 }}>
          <InputLabel htmlFor="course">Curso</InputLabel>
          <NativeSelect
            value={state.course}
            onChange={handleChangeCourse}
            inputProps={{
              name: "curso",
              id: "curso",
            }}
          >
            {courses.data.application.map((a) => {
              return <option value={a.course}> {a.course} </option>;
            })}
          </NativeSelect>
        </FormControl>
        <FormControl className="formControl">
          <InputLabel htmlFor="semestre">Semestre</InputLabel>
          <NativeSelect
            value={state.semestre}
            onChange={handleChange}
            inputProps={{
              name: "semestre",
              id: "semestre",
            }}
          >
            <option value={"2025-1"}>2025-1</option>
            <option value={"2024-2"}>2024-2</option>
            <option value={"2024-1"}>2024-1</option>
            <option value={"2023-2"}>2023-2</option>
            <option value={"2023-1"}>2023-1</option>
            <option value={"2022-2"}>2022-2</option>
            <option value={"2022-1"}>2022-1</option>
          </NativeSelect>
        </FormControl>
      </div>
      <Grid container>
        <div
          style={{
            display: "table",
            tableLayout: "fixed",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          {
            <MUIDataTable
              title="Inscrições"
              data={dataView}
              columns={columns}
              options={{
                responsive: "scroll",
                rowsPerPageOptions: [50, 100, 200],
                rowsPerPage: 200,
                selectableRows: false,
                filterType: "multiselect",
                onCellClick: (_, cmeta) => {
                  //const cname = dataView[cmeta.colIndex].name;
                  const record = data[cmeta.dataIndex];
                  setState({ ...state, application: record });
                },
                textLabels,
              }}
            />
          }
        </div>
      </Grid>
    </>
  );
});

export default List;

export const query = gql`
  query Applications($semestre: String, $course: String, $id: Int) {
    application(
      where: {
        semestre: { _eq: $semestre }
        course: { _eq: $course }
        id: { _eq: $id }
        deleted_at: { _is_null: true }
      }
      order_by: { created_at: asc }
    ) {
      id
      course
      status
      created_at
      nivel
      person
      personByPerson {
        id
        email
        first_name
        last_name
        marital_status
        occupation
        cpf
        rg
        postal_code
        address1
        address2
        n_address
        district
        city
        state
        cell_phone
        data_nascimento
        nacionalidade
        uf_rg
        rg_orgao_expedidor
        naturalidade
      }
      idade
      etnia
      graduado
      curso_graduacao
      tempo_graduacao
      saude_publica
      funcao_saude
      professor
      renda_pessoal
      familiares
      renda_per_capita
      experiencia_mf
      motivo_mf
      historia_mf
      aplicar_conhecimentos_mf
      fechar_uma_semana
      horario_mf
      horario_mf_n2
      horario_mf_n3
      horario_act_para_criancas_e_adolescentes
      recebeu_info
      utm
      utm_others
      forma_pagamento_esp
      forma_pagamento_esp_n2
      forma_pagamento_esp_n3
      forma_pagamento_pre_inscricao_fap
      forma_pagamento_RFT_completo
      forma_pagamento_RFT_modulo1
      forma_pagamento_act_para_criancas_e_adolescentes_basico
      forma_pagamento_act_para_criancas_e_adolescentes_completo
      forma_pagamento_ffc
      horario_rft
      horario_ffc
      vencimento
      comment
      cpf_arquivo
      id_arquivo
      certidao_arquivo
      endereco_arquivo
      diploma_frente
      diploma_verso
      historico_arquivo
      experiencia_act
      motivo_act
      horario_act
      horario_fap
      associado
      cartao_boleto
      forma_pagamento_for
      semestre
      course
      bolsa
      tipo
      consultor
      bolsa_aprovada
      data_aprovacao
      bolsa_obs
      contrato
      consultant {
        email
        person
        personByPerson {
          first_name
          last_name
          email
        }
      }
      serasa_aprovado
      termo_carga_mf_esp
      termo_carga_mf_for
      termo_carga_mf_for_n2
      termo_frequencia
      termo_aula_online
      termo_aula_online_n2
      termo_atividades_extraclasse
      termo_executar_atividades
      termo_70_porcento
      termo_70_porcento_n2
      comentario_mf
      fatura_id
      fatura_status
      ultimaTurma
      forma_pagamento
      cupom
      payment_plan
      paymentPlanByPaymentPlan {
        valor_parcela
        parcelas
        valor_total
        dia_vencimento
      }
    }
  }
`;

const hidden_columns = [
  "marital_status",
  "occupation",
  "postal_code",
  "address1",
  "address2",
  "n_address",
  "district",
  "city",
  "state",
  "idade",
  "etnia",
  "graduado",
  "curso_graduacao",
  "tempo_graduacao",
  "saude_publica",
  "funcao_saude",
  "professor",
  "renda_pessoal",
  "familiares",
  "experiencia_mf",
  "motivo_mf",
  "historia_mf",
  "aplicar_conhecimentos_mf",
  "fechar_uma_semana",
  "horario_mf",
  "horario_mf_n2",
  "horario_mf_n3",
  "recebeu_info",
  "forma_pagamento_esp",
  "forma_pagamento_esp_n2",
  "forma_pagamento_esp_n3",
  "vencimento",
  "comment",
  "cpf_arquivo",
  "id_arquivo",
  "certidao_arquivo",
  "endereco_arquivo",
  "diploma_frente",
  "diploma_verso",
  "historico_arquivo",
  "experiencia_act",
  "motivo_act",
  "horario_act",
  "horario_fap",
  "horario_act_para_criancas_e_adolescentes",
  "associado",
  "cartao_boleto",
  "forma_pagamento_for",
  "semestre",
  "bolsa",
  "bolsa_aprovada ",
  "serasa_aprovado",
  "termo_carga_mf_esp",
  "termo_carga_mf_for",
  "termo_carga_mf_for_n2",
  "termo_frequencia",
  "termo_aula_online",
  "termo_aula_online_n2",
  "termo_atividades_extraclasse",
  "termo_executar_atividades",
  "termo_70_porcento",
  "termo_70_porcento_n2",
  "comentario_mf",
  "forma_pagamento",
];

export const selectCourses = gql`
  query MyQuery {
    application(distinct_on: course) {
      course
    }
  }
`;
