export default function CheckRecovery(quiz_relations, grades) {
  const quizPairs = {
    1177: 1162,
    1162: 1177,
    1163: 1178,
    1178: 1163,
  };

  const nonRecoveryQuizzes = quiz_relations.filter(
    (relation) =>
      !relation.quizByQuiz.name.toUpperCase().includes("RECUPERAÇÃO")
  );

  const allQuizzesAttempted = nonRecoveryQuizzes.every((relation) => {
    const quizId = relation.quizByQuiz.id;
    const hasAttempt = relation.quizByQuiz.quiz_attempts.length > 0;

    if (quizId in quizPairs) {
      const pairedQuizId = quizPairs[quizId];
      const pairedQuiz = quiz_relations.find(
        (q) => q.quizByQuiz.id === pairedQuizId
      );

      const pairedHasAttempt =
        pairedQuiz && pairedQuiz.quizByQuiz.quiz_attempts.length > 0;

      return hasAttempt || pairedHasAttempt;
    }

    return hasAttempt;
  });

  let retake = false;
  if (grades && grades.disciplina_grades && allQuizzesAttempted) {
    grades.disciplina_grades.forEach((grade) => {
      if (grade.grade < 7) {
        retake = true;
      }
    });
  }

  return retake;
}
